export const UNIT = 1000;

export const LENGTH_STEP = 500; // in mm
export const HEIGHT_STEP = 100; // in mm

export default null;

export const virtualPageViewTypes = {
  HOMEPAGE: {
    pathname: '/',
    title: 'Homepage',
  },
  SITES: {
    pathname: '/sites',
    title: 'Sites',
  },
  SITES_RESULTS: {
    pathname: '/sites-results',
    title: 'Sites Results',
  },
  SITE_DETAILS: {
    pathname: '/site-details',
    title: 'Site Details',
  },
  SITE_AVAILABILITY: {
    pathname: '/site-availability',
    title: 'Site Availability',
  },
  CROSSINGS: {
    pathname: '/crossings',
    title: 'Crossings',
  },
  CROSSINGS_RESULTS: {
    pathname: '/crossings-results',
    title: 'Crossings Results',
  },
  CROSSING_DETAILS: {
    pathname: '/crossing-details',
    title: 'Site Details',
  },
  CROSSING_AVAILABILITY: {
    pathname: '/crossing-availability',
    title: 'Crossing Availability',
  },
  CHECKOUT_STAGE_1: {
    pathname: '/checkout-stage-1',
    title: 'Checkout Stage 1',
  },
  CHECKOUT_STAGE_2: {
    pathname: '/checkout-stage-2',
    title: 'Checkout Stage 2',
  },
  CHECKOUT_STAGE_3: {
    pathname: '/checkout-stage-3',
    title: 'Checkout Stage 3',
  },
  CHECKOUT_STAGE_4: {
    pathname: '/checkout-stage-4',
    title: 'Checkout Stage 4',
  },
  BASKET: {
    pathname: '/basket',
    title: 'Basket',
  },
};

export const USER_DECLINED_UP_SELL_IN_SESSION = 'userDeclinedUpSell';

export const PRESERVE_MEMBERSHIP_FORM_IN_SESSION = 'preserveMembershipForm';

export const LOCATION_SEARCH_COORDINATES_IN_SESSION = 'locationSearchCoordinates';

export const CAMPSITE_SEARCH_TRIGGER_IN_SESSION = 'campsiteSearchTrigger';

export const CONFIG_LOAD_COMPLETE_TIME_IN_SESSION = {
  SHARED_CONFIG: 'sharedConfigLoadCompleteTime',
  SITES_CONFIG: 'sitesConfigLoadCompleteTime',
  CROSSINGS_CONFIG: 'crossingsConfigLoadCompleteTime',
};

export const USER_LOCATION_ACCEPTANCE = 'locationPermission';

export const USER_LAST_LOCATION = 'lastKnownLocation';

export const USER_LOCATION = 'location';

export const MAX_SITES_VISIBLE_FOR_PRICES = 20;

export const QUOTE_DETAILS_IN_LOCAL_STORAGE = 'quoteDetails';

export const errorCodes = {
  // Add other error codes here
  BookingQuoteViewWrongProductRequested: 254,
};

export const PORTAL_ID = 'portal';
